import { useFormContext } from "react-hook-form";

import { IVestingBox } from "@framework/types";

import { SelectInput } from "../../../inputs/select";
import { durationOptions, stepsShapes, timeValues } from "../constants";
import { Time } from "../../../../shared";

export const DurationDropdown = () => {
  const form = useFormContext<IVestingBox>();
  const shape = form.watch("shape");

  const disabledOptions = stepsShapes.includes(shape) ? [timeValues[Time.MONTH]] : [];

  return <SelectInput name="duration" options={durationOptions} disabledOptions={disabledOptions} required />;
};
