import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useWatch } from "react-hook-form";

import type { IVestingBox } from "@framework/types";

import { VestingBoxContent } from "../../../../tables/vesting-content";

export const BoxContent: FC = () => {
  const vestingBox: IVestingBox | undefined = useWatch({ name: "vestingBox" });

  if (!vestingBox || !vestingBox.content?.components?.length) {
    return null;
  }

  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant="h6">
        <FormattedMessage id="pages.vesting.boxContent.title" />
      </Typography>
      <VestingBoxContent vestingBox={vestingBox} />
    </Box>
  );
};
