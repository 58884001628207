import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { Web3ContextType } from "@web3-react/core";
import { UseFormReturn } from "react-hook-form";
import { BigNumberish } from "ethers";

import { FormWrapper } from "@dzambalaorg/mui-form";
import { useApi } from "@dzambalaorg/provider-api-firebase";
import { PageHeader } from "@dzambalaorg/mui-page-layout";
import { useMetamask, useSystemContract, useBalance } from "@dzambalaorg/react-hooks-eth";
import { ContractType, IAssetComponent, IContract, IVestingBox } from "@framework/types";
import { cleanUpAsset, convertDatabaseAssetToTokenTypeAsset } from "@framework/exchange";

import { emptyValues, validationSchema, VestingBoxForm } from "../../../../../components/forms";
import { Breadcrumbs } from "../../../../../components/breadcrumbs";
import { useAllowance } from "./use-allowance";

export const CreateVestingBox = () => {
  const navigate = useNavigate();
  const api = useApi();
  const metaFnWithAllowance = useAllowance(
    async (_web3Context: Web3ContextType, values: IVestingBox, form: UseFormReturn<IVestingBox>) => {
      const {
        content,
        template,
        period,
        growthRate,
        cliff,
        afterCliffBasisPoints,
        imageUrl,
        backgroundImg,
        title,
        description,
        shape,
        duration,
        wallet,
        vestingBoxCat,
      } = values;

      const preparedValues = {
        title,
        description,
        imageUrl,
        shape,
        duration,
        growthRate,
        wallet,
        vestingBoxCat,
        backgroundImg: backgroundImg || null,
        content: cleanUpAsset(content),
        price: cleanUpAsset(template?.price),
        contractId: template?.contractId,
        period: period || 1,
        cliff: cliff || 0,
        afterCliffBasisPoints: afterCliffBasisPoints || 0,
        cap: template?.cap.toString() || "0",
      };

      await api.fetchJson({
        url: "/vesting/boxes",
        method: "POST",
        data: preparedValues,
      }).then(() => {
        form.reset();
        navigate(-1);
        return null;
      });
    },
    { hasApproveTx: false },
  );

  const metaFnWithBalance = useBalance(
    async (
      web3Context: Web3ContextType,
      values: IVestingBox,
      form: UseFormReturn<IVestingBox>,
      systemContract: IContract,
    ) => {
      const assets = convertDatabaseAssetToTokenTypeAsset(
        values.content?.components as unknown as Array<IAssetComponent>,
      );
      const numericCap = Number(values.template?.cap);
      const cap = numericCap !== 0 ? numericCap : 1;

      for (let i = 0; i < cap - 1; i++) {
        assets.push({ ...assets[0] });
      }

      return metaFnWithAllowance(
        {
          contract: values.content?.components[0].contract?.address as string,
          assets,
          allowance: { ownerAddress: values.wallet, senderAddress: systemContract.address },
        },
        web3Context,
        values,
        form,
      );
    },
  );

  const metaFnWithSystemContract = useSystemContract(
    async (
      values: IVestingBox,
      web3Context: Web3ContextType,
      form: UseFormReturn<IVestingBox>,
      systemContract: IContract,
    ) => {
      const numericCap = Number(values.template?.cap);
      const amount =
        numericCap > 0
          ? (((Number(values.content?.components[0].amount) / 1e18) * Number(values.template?.cap)) as BigNumberish)
          : ((Number(values.content?.components[0].amount) / 1e18) as BigNumberish);

      return metaFnWithBalance(
        {
          wallet: values.wallet,
          contract: values.content?.components[0].contract?.address as string,
          amount,
        },
        web3Context,
        values,
        form,
        systemContract,
      );
    },
  );

  const metaFn = useMetamask((values: IVestingBox, form: any, web3Context: Web3ContextType) => {
    return metaFnWithSystemContract(ContractType.EXCHANGE, values, web3Context, form);
  });

  const handleConfirm = async (values: IVestingBox, form: any) => {
    return metaFn(values, form);
  };

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "vesting", "vesting.boxes", "vesting.create"]} />
      <PageHeader message="pages.vesting.create.title" />
      <FormWrapper initialValues={emptyValues} onSubmit={handleConfirm} validationSchema={validationSchema}>
        <VestingBoxForm />
      </FormWrapper>
    </Grid>
  );
};
