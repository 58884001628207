import type { RouteObject } from "react-router-dom";

import { nativeRoutes } from "./native/routes";
import { erc20Routes } from "./erc20/routes";

// prettier-ignore
export const hierarchyRoutes: Array<RouteObject> = [
  ...nativeRoutes,
  ...erc20Routes,
];
