import { UseFormReturn } from "react-hook-form";

import { IVestingBox } from "@framework/types";

import { TOption } from "../../inputs/select";

const MONTH = 30;

type BasicStepData = {
  duration: number; // input duration in days
  period: number; // input period in days
};

type ExponentialStepData = BasicStepData & { growthRate: number };

export const generateLinearSteppedData = (config: BasicStepData) => {
  const durationMonths = config.duration / MONTH;
  const periodMonths = config.period / MONTH;
  const steps = [];

  for (let i = 0; i <= durationMonths; i++) {
    steps.push({
      fn: `${(i * 100) / durationMonths} + ${100 / durationMonths}`,
      range: [i * periodMonths, (i + 1) * periodMonths],
    });
  }

  return steps;
};

export const generateExponentialSteppedData = (config: ExponentialStepData) => {
  const durationMonths = config.duration / MONTH;
  const periodMonths = config.period / MONTH;
  const growthRate = config.growthRate / 100;
  const steps = [];

  for (let i = 0; i <= durationMonths; i++) {
    const yValue = Math.pow((i + 1) / durationMonths, growthRate) * 100;
    steps.push({
      fn: `${yValue}`,
      range: [i * periodMonths, (i + 1) * periodMonths],
    });
  }

  return steps;
};

export const generateHyperbolicSteppedData = (config: BasicStepData) => {
  const durationMonths = config.duration / MONTH;
  const periodMonths = config.period / MONTH;
  const steps = [];

  for (let i = 0; i <= durationMonths; i++) {
    const yValue = ((i + 1) / durationMonths / ((i + 1) / durationMonths + 1)) * 100 * 2;
    steps.push({
      fn: `${yValue}`,
      range: [i * periodMonths, (i + 1) * periodMonths],
    });
  }

  return steps;
};

export const optionsFilterByDuration = (
  value: number,
  duration: number,
  form: UseFormReturn<IVestingBox>,
  valueKey: keyof IVestingBox,
  options: Array<TOption<number>>,
) => {
  const filteredOptions = options.filter(option => option.value < duration);
  if (value >= duration) {
    void form.setValue(valueKey, filteredOptions[0].value, { shouldDirty: true });
  }
  return filteredOptions;
};
